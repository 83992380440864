import React from 'react'

const Contact = (props) => (
    <section id="contact">
        <div className="inner">
            <section>
                {/* <form method="post" action="#">
                    <div className="field half first">
                        <label htmlFor="name">Name</label>
                        <input type="text" name="name" id="name" />
                    </div>
                    <div className="field half">
                        <label htmlFor="email">Email</label>
                        <input type="text" name="email" id="email" />
                        <input type="text" name="honeypot" style={{display: 'none' }}></input>
                    </div>
                    <div className="field">
                        <label htmlFor="message">Message</label>
                        <textarea name="message" id="message" rows="6"></textarea>
                    </div>
                    <ul className="actions">
                    <li>              <input type="hidden" name="redirectTo" value="/"></input>
                    <input type="submit" value="Submit" className="special" /></li>
                    <li><input type="reset" value="Clear" /></li>
                    </ul>
                </form> */}
                <section>
                    <div className="contact-method">
                        <h3><i>非営利型 一般社団法人</i></h3><h2>都市構造可視化推進機構</h2>
                        
                        <h3><i>Non-profit incorporated association</i></h3><h2>Urban Structure Visualization Promotion Organization</h2>


                        {/* <a href="#">information@untitled.tld</a> */}
                    </div>
                </section>

            </section>
            <section className="split">
            <section>
                    <div className="contact-method">
                        <span className="icon alt fa-home"></span>
                        <h3>Address</h3>
                        <span>〒812-0028<br/>
                        福岡県福岡市<br/>
                        博多区須崎町5-10-201
                        </span>
                        <br/><br/>
                        <span>
5-10-201 Susaki-machi, Hakata-ku, Fukuoka-shi, Fukuoka-ken, 8120028 JAPAN
                        </span>

                    </div>
                </section>

                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-phone"></span>
                        <h3>Phone</h3>
                        <span>(092) 292-0639</span><br/>
                        +81 92 292 0639
                    </div>
                </section>

                <section>
                    <div className="contact-method">
                        <span className="icon alt fa-envelope"></span>
                        <h3>Email</h3>
                        admin@usvpro.com

                        {/* <a href="#">information@untitled.tld</a> */}
                    </div>
                </section>

            </section>
        </div>
    </section>
)

export default Contact
